import React from "react";
// import { Tabs, TabList, Tab, TabPanel } from "react-tabs";

// import { AboutShreeGuruRavidasData } from "../../../utils/constants";
import About from "./About";
// import Doha from "./Doha";

function AboutShreeGuruRavidas() {
  // const { tabs } = AboutShreeGuruRavidasData;
  return (
    // <Tabs>
    //   <TabList>
    //     {tabs &&
    //       tabs.map((tabTitle) => {
    //         return <Tab key={tabTitle}>{tabTitle}</Tab>;
    //       })}
    //   </TabList>
    //   <TabPanel>
    //     <About />
    //   </TabPanel>
    //   <TabPanel>
    //     <Doha />
    //   </TabPanel>
    // </Tabs>
    <About />
  );
}

export default AboutShreeGuruRavidas;
