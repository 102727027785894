import React from "react";

import "./Marquee.css";
import { homeData } from "../../utils/constants";
import Image from "../image/Image";

function Marquee() {
  const { cartContainer2 } = homeData;
  const { images } = cartContainer2;
  return (
    <div className="marquee">
      <div className="marquee-content">
        {images &&
          images.map((key) => {
            return (
              <div
                key={key}
                className="image-container-padding image-border all-border mr-top"
              >
                <Image
                  key={key}
                  alt={key}
                  className="gallery-image"
                  src={key}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default Marquee;
