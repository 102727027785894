import React from "react";
import Image from "../../image/Image";

import "./Images.css";

function Images({ images }) {
  return (
    <div className="image-container">
      {images &&
        Object.keys(images).map((key) => {
          return (
            <div
              key={key}
              className="image-container-padding image-border all-border"
            >
              <Image
                key={key}
                alt={key}
                className="gallery-image"
                src={images[key]}
              />
            </div>
          );
        })}
    </div>
  );
}

export default Images;
