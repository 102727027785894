import React from "react";
import "./About.css";
import { aboutData } from "../../utils/constants";

import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import AboutShreeGuruRavidas from "./shree-guru-ravidass/AboutShreeGuruRavidas";
import Mission from "./mission/Mission";
import Amritbani from "./amritbani/Amritbani";
import HolyPlaces from "./holy-places/HolyPlaces";
import SukadevMaharaj from "./sukadev-maharaj/SukadevMaharaj";

function About() {
  const { tabs } = aboutData;
  return (
    <Tabs>
      <TabList>
        {tabs &&
          tabs.map((tabTitle) => {
            return <Tab key={tabTitle}>{tabTitle}</Tab>;
          })}
      </TabList>
      <TabPanel>
        <Mission />
      </TabPanel>
      <TabPanel>
        <AboutShreeGuruRavidas />
      </TabPanel>
      <TabPanel>
        <Amritbani />
      </TabPanel>
      <TabPanel>
        <SukadevMaharaj />
      </TabPanel>
      <TabPanel>
        <HolyPlaces />
      </TabPanel>
    </Tabs>
  );
}

export default About;
