import React from "react";
import "./Footer.css";
import { footerData } from "../../utils/constants";
function Footer() {
  const { copyright } = footerData;
  return (
    <div className="footer" id="footer">
      {copyright}
    </div>
  );
}

export default Footer;
