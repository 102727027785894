import amritbaniHindiFilePdf from "./pdf/hindi.pdf";
import amritbaniEnglishFilePdf from "./pdf/english.pdf";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";

function Amritbani() {
  return (
    <Tabs>
      <TabList>
        <Tab>Hindi</Tab>
        <Tab>English</Tab>
      </TabList>
      <TabPanel>
        <iframe
          title="Amritbani"
          style={{ width: "100%", height: "65vh" }}
          src={amritbaniHindiFilePdf}
          frameborder="0"
        >
          <p>Your web browser doesn't support iframes.</p>
        </iframe>
      </TabPanel>
      <TabPanel>
        <iframe
          title="Amritbani"
          style={{ width: "100%", height: "65vh" }}
          src={amritbaniEnglishFilePdf}
          frameborder="0"
        >
          <p>Your web browser doesn't support iframes.</p>
        </iframe>
      </TabPanel>
    </Tabs>
  );
}

export default Amritbani;
