import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "./Gallery.css";
import { galleryData } from "../../utils/constants";
import Images from "./images/Images";
import Videos from "./videos/Videos";

function Gallerry() {
  const { tabTitle1, tabTitle2, images, videos, iframeVideos } = galleryData;
  return (
    <Tabs>
      <TabList>
        <Tab>{tabTitle1}</Tab>
        <Tab>{tabTitle2}</Tab>
      </TabList>
      <TabPanel>
        <Images images={images} />
      </TabPanel>
      <TabPanel>
        <Videos videos={videos} iframeVideos={iframeVideos} />
      </TabPanel>
    </Tabs>
  );
}

export default Gallerry;
