import React from "react";
import "./Welcome.css";
import { trustDetails } from "../../utils/constants";

function Welcome() {
  let  welcomeNote = `Welcome to ${trustDetails.name}`;
  return (
    <div className="welcome mr-top image-container-padding">{welcomeNote}</div>
  );
}

export default Welcome;
