import React from "react";
import Image from "../../image/Image";
import temple from "./images/thirdDham.jpg";

import { holyPlaces } from "../../../utils/constants";

function ThirdDham() {
  const { thirdDham } = holyPlaces;
  return (
    <>
      <div className="about-guru-container">
        <div className="about-guru-image">
          <Image
            src={temple}
            alt="Shree Guru Ravidas temple"
            className="image-size"
          ></Image>
          <div>
            <em>(Guru Ravidas Ji Maharaj Dham in Katraj,Pune)</em>
          </div>
        </div>
        <div className="about-guru-info">
          <h2>{thirdDham.title}</h2>
          <p className="guru-history">
            <div
              dangerouslySetInnerHTML={{ __html: thirdDham.info.section1 }}
            ></div>
            <div
              className="mr-top"
              dangerouslySetInnerHTML={{ __html: thirdDham.info.section2 }}
            ></div>
            <div
              className="mr-top"
              dangerouslySetInnerHTML={{ __html: thirdDham.info.section3 }}
            ></div>
            <div
              className="mr-top"
              dangerouslySetInnerHTML={{ __html: thirdDham.info.section4 }}
            ></div>
          </p>
        </div>
      </div>
    </>
  );
}

export default ThirdDham;
