import React from "react";

function Video({ src, title, iframe }) {
  return (
    <>
      {!iframe && (
        <div className="mr-top">
          <video width="320" height="240" controls>
            <source src={src} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <h4>{title}</h4>
        </div>
      )}
      {iframe && (
        <div>
          {iframe}
          <h4>{title}</h4>
        </div>
      )}
    </>
  );
}

export default Video;
