import React from "react";
import Image from "../../image/Image";
import temple from "./images/shree-guru-ravidas.webp";

import { AboutShreeGuruRavidasData } from "../../../utils/constants";

function About() {
  const lan = "en";
  const data = AboutShreeGuruRavidasData[lan];
  return (
    <div className="about-guru-container">
      <div className="about-guru-image">
        <Image
          src={temple}
          alt="Shree Guru Ravidas temple"
          className="image-size"
        ></Image>
        <br />
        <em>(Ravidas's statue in Varanasi, Uttar Pradesh)</em>
      </div>
      <div className="about-guru-info">
        <h3>{data.mainTitle}</h3>
        <div
          className="mr-top"
          dangerouslySetInnerHTML={{
            __html: data.about,
          }}
        ></div>
        <p className="guru-history mr-top">
          <div
            dangerouslySetInnerHTML={{
              __html: data.title.section1,
            }}
          ></div>
          <div
            dangerouslySetInnerHTML={{
              __html: data.title.section2,
            }}
          ></div>
          <div
            className="mr-top"
            dangerouslySetInnerHTML={{
              __html: data.meaning.section1,
            }}
          ></div>
          <div
            className="mr-top"
            dangerouslySetInnerHTML={{
              __html: data.meaning.section2,
            }}
          ></div>
          <div
            className="mr-top"
            dangerouslySetInnerHTML={{
              __html: data.meaning.section3,
            }}
          ></div>
          <div
            className="mr-top"
            dangerouslySetInnerHTML={{
              __html: data.meaning.section4,
            }}
          ></div>
          <div
            className="mr-top"
            dangerouslySetInnerHTML={{
              __html: data.meaning.section5,
            }}
          ></div>
          <div
            className="mr-top"
            dangerouslySetInnerHTML={{
              __html: data.meaning.section6,
            }}
          ></div>
          <div
            className="mr-top"
            dangerouslySetInnerHTML={{
              __html: data.meaning.section7,
            }}
          ></div>
        </p>
      </div>
    </div>
  );
}

export default About;
