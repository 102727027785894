import React from "react";
import Welcome from "../welcome/Welcome";

import "./Home.css";
import { CardContainer, CardFooter, CardHeader } from "../card";
import { homeData, trustDetails } from "../../utils/constants";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const KnowMore = ({ link }) => {
  return (
    <Link to={link} className="nav-cta">
      Know More
    </Link>
  );
};

function Home() {
  const { cardContainer1 } = homeData;
  return (
    <div className="home-container">
      <Helmet>
        <title>{"Guru Ravidas Sabha"}</title>
      </Helmet>
      <Helmet>
        <meta
          name="description"
          content={
            trustDetails.name +
                " " +
                trustDetails.about.section1 +
                " " +
                trustDetails.about.section2
          }
        />
      </Helmet>
      <Welcome />
      <h1 className="mr-top">
        {trustDetails.name}
      </h1>
      <div
        dangerouslySetInnerHTML={{ __html: trustDetails.about.section1 }}
      ></div>
      <div
        className="mr-top"
        dangerouslySetInnerHTML={{ __html: trustDetails.about.section2 }}
      ></div>
      <div
        className="mr-top"
        dangerouslySetInnerHTML={{ __html: trustDetails.about.section3 }}
      ></div>
      <div
        className="mr-top"
        dangerouslySetInnerHTML={{ __html: trustDetails.about.section4 }}
      ></div>
      <div className="flex-wrap-container mr-top">
        <div className="card-container">
          <CardHeader
            title="Latest News"
            className="card-header-border news-color card-header-text card-header-align-text card-header-height"
          />
          <CardContainer className="card-height image-container-padding card-image-border-left-right" />
          <CardFooter
            className="all-border card-footer-padding card-footer-border-radius-bottom"
            render={<KnowMore link="/gallery" />}
          />
        </div>
        <div className="card-container">
          <CardHeader
            title="About Trust"
            className="card-header-border about-color card-header-text card-header-align-text card-header-height"
          />
          <CardContainer
            imageSrc={cardContainer1}
            className="card-height image-container-padding card-image-border-left-right"
          />
          <CardFooter
            className="all-border card-footer-padding card-footer-border-radius-bottom"
            render={<KnowMore link="/about" />}
          />
        </div>
      </div>
    </div>
  );
}

export default Home;
