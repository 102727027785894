import React from "react";
import Image from "../image/Image";
import Marquee from "../marquee/Marquee";

export default function CardContainer({ imageSrc, className }) {
  if (imageSrc) {
    return (
      <div className={className}>
        <Image
          alt="home-about"
          className="gallery-image"
          src={imageSrc.image}
        />
      </div>
    );
  }
  return (
    <div className={className}>
      <Marquee />
    </div>
  );
}
