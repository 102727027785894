import React from "react";
import Image from "../../image/Image";
import temple from "./images/firstDham.png";

import { holyPlaces } from "../../../utils/constants";

function FirstDham() {
  const { firstSham } = holyPlaces;
  return (
    <>
      <div className="about-guru-container">
        <div className="about-guru-image">
          <Image
            src={temple}
            alt="Shree Guru Ravidas temple"
            className="image-size"
          ></Image>
          <div>
            <em>(Guru Ravidas Ji Maharaj Dham in Varanasi, Uttar Pradesh)</em>
          </div>
        </div>
        <div className="about-guru-info">
          <h2>{firstSham.mainTitle}</h2>
          <p dangerouslySetInnerHTML={{ __html: firstSham.info }}></p>
          <h2 className="mr-top">{firstSham.history.title}</h2>
          <p className="guru-history">
            <div
              dangerouslySetInnerHTML={{ __html: firstSham.history.section1 }}
            ></div>
            <div
              className="mr-top"
              dangerouslySetInnerHTML={{ __html: firstSham.history.section2 }}
            ></div>
            <div
              className="mr-top"
              dangerouslySetInnerHTML={{ __html: firstSham.history.section3 }}
            ></div>
            <div
              className="mr-top"
              dangerouslySetInnerHTML={{ __html: firstSham.history.section4 }}
            ></div>
          </p>
        </div>
      </div>
    </>
  );
}

export default FirstDham;
