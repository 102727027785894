import React, { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { IoClose, IoMenu } from "react-icons/io5";
import { useMediaQuery } from "react-responsive";
import logoIMG from "../../assets/logo/logo.png";
import {
  DONATION_URL,
  donationTitle,
  websiteName,
  navLinks,
} from "../../utils/constants";

import "./Header.css";
import Image from "../image/Image";

const Header = () => {
  const isMobile = useMediaQuery({ maxWidth: "1150px" });

  const [showMenu, setShowMenu] = useState(false);
  const [headerClass, setHeaderClass] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
    setHeaderClass(!headerClass);
  };

  const toggleMenuClose = () => {
    if (isMobile) {
      setHeaderClass(!headerClass);

      setTimeout(() => {
        setShowMenu(!showMenu);
      }, 800);
    }
  };

  const closeMobileNavBar = () => {
    toggleMenuClose();
  };

  useEffect(() => {
    setShowMenu(false);
  }, [isMobile]);

  const RenderLink = () => {
    return (
      <div className="nav-menu show-menu" id="nav-menu">
        <ul className="nav-list">
          {navLinks.map((link) => {
            return (
              <li className="nav-item" key={link.id}>
                <NavLink
                  to={link.to}
                  className="nav-link"
                  onClick={closeMobileNavBar}
                >
                  {link.name}
                </NavLink>
              </li>
            );
          })}
          <li className="nav-item" key="google-link">
            {/* <Link to='/donate' className="nav-cta">Donation</Link> */}
            <a
              className="nav-cta"
              href={DONATION_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              {donationTitle}
            </a>
          </li>
        </ul>
      </div>
    );
  };

  return (
    <header
      className={`header ${
        headerClass ? "nav-header-close-height" : "nav-header-open-height"
      } ${showMenu ? "nav-header-transparent" : "nav-header-bg-color"}`}
    >
      <nav className="nav">
        {
          <Link to="/" className="nav-logo card-header-align-text">
            <Image alt="logo" src={logoIMG} className="logo-image" /> &nbsp;{" "}
            {websiteName}
          </Link>
        }

        {showMenu && (
          <div className="nav-close" id="nav-close" onClick={toggleMenuClose}>
            <IoClose />
          </div>
        )}
        {!showMenu && (
          <div className="nav-open" id="nav-open" onClick={toggleMenu}>
            <IoMenu />
          </div>
        )}

        {showMenu && <RenderLink />}

        {!isMobile && <RenderLink />}
      </nav>
    </header>
  );
};

export default Header;
