import React, { useEffect } from "react";
import "./index.css";

const GoogleTranslate = () => {
  useEffect(() => {
    if (window.google && window.google.translate && window.google.translate.TranslateElement) {
      new window.google.translate.TranslateElement(
        { pageLanguage: "en" },
        "google_translate_element"
      );
    }
  }, []); // Run only once when the component is mounted

  return <div id="google_translate_element"></div>;
};

export default GoogleTranslate;