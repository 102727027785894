import React, { useEffect, useState } from "react";
import Header from "../header/Header";
import { Outlet } from "react-router-dom";

import "./RootLayout.css";
import Footer from "../footer/Footer";
import GoogleTranslate from "../GoogleTranslate/GoogleTranslate";

function RootLayout() {
  const [fotterHeight, setFooterHeight] = useState("50");
  useEffect(() => {
    const footer = document.getElementById("footer");
    if (footer) {
      setFooterHeight(footer.clientHeight);
    }
  }, []);
  return (
    <>
      <GoogleTranslate/>
      <Header />
      <div
        className="body-container"
        style={{ marginBottom: fotterHeight + 10 + "px" }}
      >
        <Outlet />
      </div>
      <Footer />
    </>
  );
}

export default RootLayout;
