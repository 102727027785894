import React from "react";
import temple from "./Sukadev-Maharaj.jpeg";
import Image from "../../image/Image";
import { AboutSukadevMaharaj } from "../../../utils/constants";

function SukadevMaharaj() {
  const lang = "en";
  const data = AboutSukadevMaharaj[lang];
  return (
    <div className="about-guru-container">
      <div className="about-guru-image">
        <Image
          src={temple}
          alt="Shree Guru Ravidas temple"
          className="image-size"
        ></Image>
        <br />
        <em>(Sukhdev Maharaj Ji)</em>
      </div>
      <div className="about-guru-info">
        <h3>{data.mainTitle}</h3>
        <div
          className="mr-top"
          dangerouslySetInnerHTML={{
            __html: data.info,
          }}
        ></div>
      </div>
    </div>
  );
}

export default SukadevMaharaj;
