import React from "react";
import { mission } from "../../../utils/constants";

function Mission() {
  return (
    <>
      <h2>{mission.tittle1}</h2>
      <div dangerouslySetInnerHTML={{ __html: mission.content.section1 }}></div>
      <div
        className="mr-top"
        dangerouslySetInnerHTML={{ __html: mission.content.section2 }}
      ></div>
      <h3 className="mr-top">{mission.tittle2}</h3>
      <div dangerouslySetInnerHTML={{ __html: mission.statement }}></div>
    </>
  );
}

export default Mission;
