import { routes } from "./components/router/Router";
import { RouterProvider } from "react-router-dom";

function App() {
  return (
    <RouterProvider router={routes}></RouterProvider>
  );
}

export default App;
