import React from "react";
import { holyPlaces } from "../../../utils/constants";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import FirstDham from "./FirstDham";
import SecondDham from "./SecondDham";
import ThirdDham from "./ThirdDham";

function HolyPlaces() {
  const { tabs } = holyPlaces;
  return (
    <Tabs>
      <TabList>
        {tabs &&
          tabs.map((tabTitle) => {
            return <Tab key={tabTitle}>{tabTitle}</Tab>;
          })}
      </TabList>
      <TabPanel>
        <FirstDham />
      </TabPanel>
      <TabPanel>
        <SecondDham />
      </TabPanel>
      <TabPanel>
        <ThirdDham />
      </TabPanel>
    </Tabs>
  );
}

export default HolyPlaces;
