import React from "react";
import Video from "../../video/Video";

function Videos({ videos, iframeVideos }) {
  return (
    <div className="flex-wrap-container">
      {videos &&
        Object.keys(videos).map((key, index) => {
          return (
            <Video key={key} src={videos[key]} title={`Video ${index + 1}`} />
          );
        })}
      {iframeVideos &&
        iframeVideos.map((iframe, i) => {
          return (
            <Video
              key={0}
              iframe={iframe}
              title={`Video ${Object.keys(videos).length + i + 1}`}
            />
          );
        })}
    </div>
  );
}

export default Videos;
