import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import RootLayout from "../root-layout/RootLayout";
import { navLinks } from "../../utils/constants";

export const routes = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootLayout />}>
      {navLinks.map((link) => {
        return <Route key={link.id} path={link.to} index element={link.component}></Route>;
      })}
    </Route>
  )
);
