import React from "react";
import { contactData } from "../../utils/constants";
import "./Contact.css";

function Contact() {
  const {
    mainTitle,
    addressTitle,
    addressValue,
    phoneTitle,
    phoneValue,
    emailTitle,
    emailValue,
    workingTitle,
    workingValue,
    contactUsTitle,
    contactUsValue,
    trustName,
    trustTitle,
    joinUsTitle,
    joinUsValue,
  } = contactData;

  return (
    <div>
      <h2 className="contact-details">{mainTitle}</h2>
      <div className="mr-top">
        <label className="contact-label">
          <span>
            <span className="contact-details">{trustTitle}: </span>
            <span>{trustName}</span>
          </span>
        </label>
        <label className="contact-label">
          <span>
            <span className="contact-details">{addressTitle}: </span>
            <span>{addressValue}</span>
          </span>
        </label>
        <label className="contact-label">
          <span>
            <span className="contact-details">{phoneTitle}: </span>
            <span>{phoneValue}</span>
          </span>
        </label>
        <label className="contact-label">
          <span>
            <span className="contact-details">{emailTitle}: </span>
            <span>
              <a href={`mailto:${emailValue}`}>{emailValue}</a>
            </span>
          </span>
        </label>
        <label className="contact-label">
          <span>
            <span className="contact-details">{workingTitle}: </span>
            <span>{workingValue}</span>
          </span>
        </label>
        <div className="nav-list">
          <div className="mr-top">
            <a
              className="nav-cta"
              href={contactUsValue}
              target="_blank"
              rel="noopener noreferrer"
            >
              {contactUsTitle}
            </a>
          </div>
          <div className="mr-top">
            <a
              className="nav-cta"
              href={joinUsValue}
              target="_blank"
              rel="noopener noreferrer"
            >
              {joinUsTitle}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
